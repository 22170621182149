import styled from 'styled-components'
import React from 'react'
import flyImage from '../img/fly.png'
import './fly.css'

export const WindowWrapper = styled.div<{ width: number; height: number }>`
  margin: 10% 20%;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`
export const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 30px 20px;

  &.desktop {
    flex-direction: column;
    padding: 15px;
  }
`

interface IFlyProps {
  angle: number
}

const Fly = styled.img<IFlyProps>`
  position: fixed;
  height: auto;
  transform: rotate(${({ angle }) => angle}deg);
  z-index: 2;
`

export class MovingFly extends React.Component<{}, IFlyProps & { moving: boolean }> {
  state = {
    angle: 20,
    moving: false,
  }

  timeout: number | undefined

  componentDidMount() {
    const rotateFly = () => {
      const rnd = Math.random()
      this.setState({ angle: rnd * 360 })
      this.timeout = window.setTimeout(rotateFly, rnd * 20000)
    }

    rotateFly()
  }

  startMoving = () => {
    if (!this.timeout) return
    clearTimeout(this.timeout)
    this.setState({ angle: -20, moving: true })
  }

  render() {
    return <Fly src={flyImage} angle={this.state.angle} className={this.state.moving ? 'moving' : 'fly'} alt="fly" onMouseEnter={this.startMoving} />
  }
}
