import styled from 'styled-components'
import React from 'react'

export const MobileDesktop = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 30px 20px;
  padding: 20px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  box-sizing: border-box;

  & > * {
    flex-grow: 0;
  }
`

const NoteBackground = styled.div`
  background-color: ${(props) => (props.theme.color === 'black' ? 'inherit' : 'black')};
  color: ${(props) => props.theme.color};
  border-radius: 8px;
  padding: 16px;
  width: 300px;
  border: ${(props) => (props.theme.color === 'black' ? '1px solid #E9E5DF' : 'none')};
  box-sizing: border-box;
`

export const Note: React.FC = () => <NoteBackground>Currently I'm looking for a Full Stack or Front-end developer position</NoteBackground>
