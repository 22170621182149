import styled from 'styled-components'
import React from 'react'
import cmd16 from '../img/cmd16.png'
import TypeWriterEffect from 'react-typewriter-effect'
import { Window } from './window'

export const TextArea = styled.div`
  display: flex;
  align-items: baseline;
`
export const AboutWindow: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <Window onClose={onClose} img={cmd16} background={'black'} title="Administrator: Maxim Golubitsky">
    About Window [Version 1.0.0]
    <br />
    (c) Maxim Golubitsky. All rights reserved.
    <br />
    {}
    <br />
    <TextArea>
      C:\Dev{'>'}
      <TypeWriterEffect
        textStyle={{
          fontFamily: 'Courier',
          color: '#FFFFFF',
          fontWeight: 500,
          fontSize: '16px',
        }}
        startDelay={2000}
        cursorColor="#FFFFFF"
        multiText={['Hey there, welcome to my site', 'My name is Maxim Golubitsky', "I'm the Senior Full Stack Developer at TraderTools"]}
        multiTextDelay={1000}
        typeSpeed={30}
      />
    </TextArea>
  </Window>
)
