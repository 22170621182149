import React from 'react'
import rb16 from '../img/rb16.png'
import folder from '../img/folder.png'
import empty from '../img/empty.png'
import restore from '../img/restore.png'
import { Window } from './window'
import styled from 'styled-components'

const ItemWrapper = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  font-size: 12px;
  color: black;
  padding: 6px;
  user-select: none;
  box-sizing: border-box;

  &:hover {
    background-color: #e3f5ff;
  }

  & > img {
    padding-right: 10px;
  }
`

const Item: React.FC<{ txt: string }> = ({ txt }) => (
  <ItemWrapper>
    <img src={folder} alt="rb" height="auto" />
    {txt}
  </ItemWrapper>
)

const Button = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }

  & > img {
    padding-right: 10px;
  }
`

const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 54px;
  background-color: #f2f5f5;
  color: black;
  font-size: 12px;
  border-top: 1px solid #c4d1d5;
  border-bottom: 1px solid #c2cccd;
  user-select: none;
  padding-left: 10px;
  box-sizing: border-box;
`

const items = ['Unreadable code', 'Bugs', 'Code duplication']

const Items = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 260px);
  grid-template-rows: 50px;
  gap: 10px;
  width: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: black;
  font-size: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 10px;
  box-sizing: border-box;
`

export const RecycleWindow: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [hasItems, setHasItems] = React.useState(true)

  return (
    <Window onClose={onClose} background={'white'} img={rb16} title="Recycle Bin">
      <Header>
        <Button onClick={() => setHasItems(false)}>
          <img src={empty} alt="empty" />
          Empty Recycle bin
        </Button>
        <Button onClick={() => setHasItems(true)}>
          <img src={restore} alt="restore" />
          Restore all items
        </Button>
      </Header>
      <Items>{hasItems && items.map((item) => <Item txt={item} key={item} />)}</Items>
      <Footer>{hasItems ? items.length : 0} items</Footer>
    </Window>
  )
}
