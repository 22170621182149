import React from 'react'
import Draggable from 'react-draggable'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import Icon from './components/icon'
import fb from './img/fb.png'
import cmd from './img/cmd.png'
import linkedin from './img/linkedin.png'
import pdf from './img/pdf.png'
import youtube from './img/youtube.png'
import whatsapp from './img/whatsapp.png'
import rb from './img/rb.png'
import { AboutWindow } from './components/about-window'
import { RecycleWindow } from './components/recycle-window'
import { Icons, MovingFly, WindowWrapper } from './components/desktop'
import { MobileDesktop, Note } from './components/mobile'

// import TaskBar from './components/taskbar'

interface IAppState {
  isMobile: boolean
  about: boolean
  rb: boolean
}

const downloadResume = () => {
  const link = document.createElement('a')
  link.href = 'Maxim Golubitsky.pdf'
  link.target = '_blank'
  link.rel = 'noreferrer noopener'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default class App extends React.Component<{}, IAppState> {
  state: IAppState = {
    about: true,
    rb: false,
    isMobile: false,
  }

  theme: DefaultTheme

  constructor(props: {}, context: any) {
    super(props, context)
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')
    if (darkThemeMq.matches) {
      this.theme = {
        color: 'white',
        background: '#1b1b1d',
      }
    } else {
      this.theme = {
        color: 'black',
        background: 'white',
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
    this.handleWindowSizeChange()
    setTimeout((window as any).LIRenderAll, 2000)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    const w = window.innerWidth
    const isMobile = w <= 1100
    const changedToMobile = isMobile !== this.state.isMobile
    this.setState({ isMobile }, changedToMobile ? (window as any).LIRenderAll : undefined)
  }

  aboutRef = React.createRef<HTMLDivElement>()
  recycleRef = React.createRef<HTMLDivElement>()

  // noinspection HtmlUnknownAttribute
  renderMobile = () => (
    <ThemeProvider theme={this.theme}>
      <MobileDesktop>
        <Icons>
          <Icon name="LinkedIn" image={linkedin} url="https://www.linkedin.com/in/programmer/" />
          <Icon name="Resume" image={pdf} onClick={downloadResume} />
          <Icon name="Facebook" image={fb} url="https://facebook.com/mgolubitsky" />
          <Icon name="YouTube" image={youtube} url="https://www.youtube.com/@mgolubitsky" />
          <Icon
            name="Whatsapp"
            image={whatsapp}
            url="https://wa.me/972544796249?text=Hello,%20we%20have%20an%20open%20position%20that%20fits%20your%20skills"
          />
        </Icons>
        <div
          className="badge-base LI-profile-badge"
          data-locale="en_US"
          data-size="large"
          data-theme={this.theme.background === 'white' ? 'light' : 'dark'}
          data-type="VERTICAL"
          data-vanity="programmer"
          data-version="v1"
        >
          <a className="badge-base__link LI-simple-link" href="https://il.linkedin.com/in/programmer?trk=profile-badge">
            Maxim Golubitsky
          </a>
        </div>
        {/*<Note />*/}
      </MobileDesktop>
    </ThemeProvider>
  )

  renderDesktop = () => (
    <>
      <Icons className="desktop">
        <Icon name="LinkedIn" className="desktop" image={linkedin} url="https://www.linkedin.com/in/programmer/" />
        <Icon name="Resume" className="desktop" image={pdf} onClick={downloadResume} />
        <Icon name="Facebook" className="desktop" image={fb} url="https://facebook.com/mgolubitsky" />
        <Icon name="YouTube" className="desktop" image={youtube} url="https://www.youtube.com/@mgolubitsky" />
        <Icon name="Whatsapp" className="desktop" image={whatsapp} url="https://wa.me/972544796249" />
        {/*?text=Hello,%20we%20have%20an%20open%20position%20that%20fits%20your%20skills"*/}
        <Icon name="Recycle Bin" className="desktop" image={rb} onClick={() => this.setState({ rb: true })} />
        <Icon name="About" className="desktop" image={cmd} onClick={() => this.setState({ about: true })} />
      </Icons>
      <MovingFly />
      {/*<TaskBar />*/}
      <div>
        {this.state.about && (
          <Draggable handle=".handle" nodeRef={this.aboutRef}>
            <WindowWrapper ref={this.aboutRef} width={750} height={300}>
              <AboutWindow onClose={() => this.setState({ about: false })} />
            </WindowWrapper>
          </Draggable>
        )}
        {this.state.rb && (
          <Draggable handle=".handle" nodeRef={this.recycleRef}>
            <WindowWrapper ref={this.recycleRef} width={600} height={400}>
              <RecycleWindow onClose={() => this.setState({ rb: false })} />
            </WindowWrapper>
          </Draggable>
        )}
      </div>
    </>
  )

  render() {
    return this.state.isMobile ? this.renderMobile() : this.renderDesktop()
  }
}
