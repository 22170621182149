import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  border: #b8b7b0 1px solid;
  border-radius: 8px;
  color: #ffffff;
  text-align: left;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 3px 5px 15px 2px #606060;
  box-shadow: 3px 5px 15px 2px #606060;
`

const Close = styled.div`
  cursor: pointer;

  &:after {
    content: "\\D7";
    font-size: 26px;
    color: #000;
    line-height: 26px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  box-sizing: border-box;
  background-color: #ebf6f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 30px;
  padding-right: 10px;
  user-select: none;
  cursor: move;
`

const IconWrapper = styled.div`
  display: flex;
  color: black;
  align-items: center;
  font-size: 12px;
`

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 5px 0 10px;
`

const Body = styled.div<{ background: string }>`
  background-color: ${({ background }) => background};
  width: 100%;
  height: calc(100% - 30px);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

interface IWindow {
  img: string
  title: string
  background: string
  children: React.ReactNode
  onClose: () => void
}

export const Window: React.FC<IWindow> = ({ onClose, background, img, title, children }) => (
  <Wrapper>
    <Header className="handle">
      <IconWrapper>
        <Icon src={img} />
        {title}
      </IconWrapper>
      <Close onClick={onClose} />
    </Header>
    <Body background={background}>{children}</Body>
  </Wrapper>
)
