import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.color || 'white'};
  width: 80px;
  border-radius: 3px;

  &.desktop:hover {
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
  }

  &.desktop {
    text-shadow: 4px 4px 5px #000000, -3px -1px 5px #000000, -3px 4px 5px #000000, 3px -2px 5px #000000;
  }
`

const Image = styled.img`
  width: 48px;
`

interface IIconProps {
  image: string
  name: string
  url?: string
  onClick?: () => void
  className?: string
}

const Icon: React.FC<IIconProps> = ({ image, onClick, name, url, className }) => {
  const clickHandler = onClick || (() => window.open(url, '_blank', 'noreferrer'))

  return (
    <Wrapper className={className} onClick={clickHandler}>
      <Image src={image} alt={name} />
      {name}
    </Wrapper>
  )
}

export default Icon
